<template>
  <v-card
    class="mt-2"
    :style="`background: linear-gradient(to right, #ffffff 0%, ${cardColor} 100%)`"
  >
    <v-card-text
      :class="`${$route.params.postId ? 'click-off' : 'click-on'}`"
      @click="redirectToRelatedPostsPage()"
    >
      <v-row no-gutters class="mb-3">
        <v-col class="d-flex justify-end">
          <v-img max-width="20" :src="onOffIconSrc" alt="valid-icon"></v-img>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="4" class="d-flex flex-column justify-center align-center">
          <v-img
            max-width="60"
            max-height="60"
            class="profile-image"
            :src="srcProfileImage"
          ></v-img>
          <span class="uppercase mt-2">{{ userName }}</span>
          <span class="uppercase">{{ userEmail }}</span>
        </v-col>
        <v-col cols="8">
          <v-card class="mb-2" color="grey lighten-5">
            <v-card-text>
              <v-alert
                class="post-answered"
                color="#e5e5e5"
                elevation="0"
                border="left"
                v-if="isResponseToTheOtherPost"
              >
                <span class="dark-grey-text replied-post-username">{{
                  repliedPostUsername
                }}</span>
                <div
                  class="dark-grey-text mt-3"
                  v-html="responseToThePostContent"
                ></div>
              </v-alert>

              <v-alert
                class="fallacy-alert"
                color="grey"
                outlined
                elevation="2"
                v-if="isFallacy"
              >
                <span class="black--text">{{ fallacyDescription }}</span>
              </v-alert>

              <div class="black--text" v-html="postContent"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col class="d-flex justify-end">
          <span>{{ postDate }}</span>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-row class="actions-row">
        <v-col v-if="!isFallacy" class="d-flex justify-start ml-2">
          <v-btn
            @click="openInteractionDialog('Responder/Concordar')"
            color="#235737"
            class="mr-2 mt-1"
            :loading="loading"
            small
          >
            <v-img max-width="11" class="mr-1" :src="phiIconSrc"></v-img>
          </v-btn>
          <v-btn
            @click="openInteractionDialog('Discordar')"
            color="#a35362"
            class="mr-2 mt-1"
            :loading="loading"
            small
          >
            <v-img max-width="11" class="mr-1" :src="phiNegIconSrc"></v-img>
          </v-btn>
          <v-btn
            @click="openInteractionDialog('Perguntar')"
            color="#3972af"
            class="mr-2 mt-1"
            :loading="loading"
            small
          >
            <v-img max-width="12" class="mr-1" :src="questionIconSrc"></v-img>
          </v-btn>
          <v-btn
            @click="openInteractionDialog('Acusar de falácia')"
            color="grey darken-1"
            class="mr-2 mt-1"
            :loading="loading"
            small
          >
            <v-img max-width="15" class="mr-1" :src="phiUpperIconSrc"></v-img>
          </v-btn>
        </v-col>

        <v-col class="d-flex justify-end mr-2">
          <v-btn
            v-if="isNotStudent"
            @click="openUpdatePostDialog()"
            color="primary"
            class="mr-2 mt-1"
            :loading="loading"
            title="Editar"
            icon
          >
            <v-icon>mdi-file-edit</v-icon>
          </v-btn>
          <v-btn
            v-if="isNotStudent"
            @click="deletePosts()"
            color="primary"
            class="mr-2 mt-1"
            :loading="loading"
            title="Excluir"
            icon
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
            v-if="isAdmOrModerator && isFallacy"
            @click="validatePosts()"
            color="primary"
            class="mr-2 mt-1"
            :loading="loading"
            title="Validar"
            icon
          >
            <v-icon>mdi-lock-open-check</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>

    <!-- modal de inserir post -->
    <v-dialog v-model="interactionDialog" persistent max-width="850">
      <InteractGameForm
        :interactionType="interactionType"
        :mainPost="0"
        :origin="originPage"
        :responseToThePostId="postId"
        @close-dialog="interactionDialog = false"
      />
    </v-dialog>

    <!-- modal de editar post -->
    <v-dialog v-model="updatePostDialog" persistent max-width="850">
      <InteractGameForm
        :interactionType="interactionTypeToUpdate"
        :mainPost="0"
        :origin="originPage"
        :postId="postId"
        :mode="'update'"
        @close-dialog="updatePostDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { baseUrl } from "@/config/config.json";
import errorHandler from "@/helpers/error_handler";
import { mapState } from "vuex";
import profileImage from "@/assets/profile.png";
import InteractGameForm from "@/components/Games/InteractGameForm.vue";
import onIcon from "@/assets/on.png";
import offIcon from "@/assets/off.png";
import phiIcon from "@/assets/fi.png";
import phiNegIcon from "@/assets/fiNeg.png";
import phiUpperIcon from "@/assets/phiUpper4.png";
import questionIcon from "@/assets/question.png";

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    responseToMainPost: {
      type: Boolean,
      default: true,
    },
    userIsModerator: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InteractGameForm,
  },
  data() {
    return {
      loading: false,
      interactionDialog: false,
      updatePostDialog: false,
      interactionType: null,
      interactionTypeToUpdate: null,
      postToUpdateId: null,
      srcOnIcon: onIcon,
      srcOffIcon: offIcon,
      phiIconSrc: phiIcon,
      phiNegIconSrc: phiNegIcon,
      phiUpperIconSrc: phiUpperIcon,
      questionIconSrc: questionIcon,
      glossary: {
        1: "Responder/Concordar",
        2: "Discordar",
        3: "Perguntar",
        4: "Acusar de falácia",
      },
    };
  },
  methods: {
    openInteractionDialog(type) {
      this.interactionType = type;
      this.interactionDialog = true;
    },
    openUpdatePostDialog() {
      this.interactionTypeToUpdate = this.glossary[this.postType];
      this.updatePostDialog = true;
    },
    async deletePosts() {
      try {
        const confirm = await this.$root.$confirm(
          "Alerta de AÇÃO IRREVERSÍVEL",
          `Deseja realmente remover este post? `
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/post/${this.postId}/deletar`;

        await this.$axios.delete(url);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        errorHandled.map((e) => this.$toast.error(e));
      } finally {
        this.loading = false;
      }
    },
    async validatePosts() {
      try {
        const confirm = await this.$root.$confirm(
          "Confirmação",
          `Deseja realmente validar este post? `
        );

        if (!confirm) return;

        if (this.isValid)
          return this.$root.$errorDialog(["Este post já está validado"]);

        this.loading = true;

        let url = `/post/${this.postId}/validar`;

        await this.$axios.put(url, {
          gameId: this.gameId,
          origin: this.originPage,
          mainPost: 0,
        });
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        errorHandled.map((e) => this.$toast.error(e));
      } finally {
        this.loading = false;
      }
    },
    redirectToRelatedPostsPage() {
      this.$router.push(
        `/sala/${this.roomId}/jogo/${this.gameId}/post/${this.postId}`
      );
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    postId() {
      return this.post.id ? parseInt(this.post.id) : this.$route.params.postId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    gameId() {
      return this.$route.params.gameId;
    },
    isNotStudent() {
      return this.userData.Group.group_code !== 1 ? true : false;
    },
    isAdmOrModerator() {
      return this.userData.Group.group_code !== 1 || this.userIsModerator
        ? true
        : false;
    },
    isValid() {
      return this.post.valid;
    },
    onOffIconSrc() {
      return this.post.type === 4 && !this.post.valid
        ? this.srcOffIcon
        : this.srcOnIcon;
    },
    srcProfileImage() {
      if (this.post.User && this.post.User.profile_photo)
        return `${baseUrl}/uploads/usersavatar/${this.post.User.profile_photo}`;

      return profileImage;
    },
    userName() {
      return this.post.User && this.post.User.firstName
        ? this.post.User.firstName
        : "Carregando nome...";
    },
    userEmail() {
      return this.post.User && this.post.User.email
        ? this.post.User.email
        : "Carregando email...";
    },
    postContent() {
      return `${this.post.content}`;
    },
    postDate() {
      return `${
        this.post.createdAt == this.post.updatedAt
          ? `${this.$moment(this.post.createdAt).format(
              "DD/MM/YYYY [ás] HH:mm:ss"
            )}`
          : `Editado em: ${this.$moment(this.post.updatedAt).format(
              "DD/MM/YYYY [ás] HH:mm:ss"
            )}`
      }`;
    },
    cardColor() {
      if (this.post.type === 1) return "#245739";
      else if (this.post.type === 2) return "#a1292a";
      else if (this.post.type === 3) return "#3b73b0";
      else if (this.post.type === 4) return "#6d6d6c";

      return "#fafafa";
    },
    isFallacy() {
      return this.post.type === 4;
    },
    postType() {
      return this.post.type;
    },
    originPage() {
      //só vai ter this.$route.params.postId quando for a página do jogo
      return this.$route.params.postId ? "singlePostPage" : "gamePage";
    },
    isResponseToTheOtherPost() {
      return this.post.responseToThePost ? true : false;
    },
    fallacyDescription() {
      return this.post.type === 4 ? this.post.fallaciesType.description : null;
    },
    repliedPostUsername() {
      return `@${this.post.responseToThePost.User.firstName} ${this.post.responseToThePost.User.lastName}`;
    },
    responseToThePostContent() {
      return `${this.post.responseToThePost.content}`;
    },
  },
};
</script>

<style scoped>
.uppercase {
  text-transform: uppercase;
}

.v-card__actions {
  background-color: #fafafa;
}

.profile-image {
  border-radius: 50%;
}

.replied-post-username {
  font-size: 1.8rem;
}

.dark-grey-text {
  color: #585858;
}

.click-on:hover {
  cursor: pointer;
}

.actions-row {
  min-height: 55px;
}
</style>
