<template>
  <v-card :loading="loading">
    <v-toolbar>
      <v-toolbar-title>{{ cardTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="closeDialog" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-row
        v-if="loadingFallacies"
        justify="center"
        align="center"
        class="my-5"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>

      <v-row
        v-if="
          !loadingFallacies &&
          fallaciesTypes.length &&
          interactionType == 'Acusar de falácia'
        "
      >
        <v-col>
          <v-radio-group v-model="form.fallaciesTypeId">
            <v-radio
              v-for="fallacy in fallaciesTypes"
              :key="fallacy.id"
              :label="`${fallacy.group} - ${fallacy.description}`"
              :value="fallacy.id"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="mt-3">
        <v-col>
          <vue-editor
            :editorOptions="editorOptions"
            v-model="form.content"
          ></vue-editor>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
        >{{ buttonText }}</v-btn
      >
    </v-card-actions>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Operação realizada com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="closeSuccessDialog()"
            >Ok</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { VueEditor } from "vue2-editor";
import errorHandler from "@/helpers/error_handler";

export default {
  props: {
    interactionType: {
      type: String,
      required: true,
    },
    mainPost: {
      //indica se é uma resposta ao post principal
      type: Number,
      default: 1,
    },
    responseToThePostId: {
      type: Number,
      default: null,
    },
    origin: {
      //indica se é a página do jogo ou de um determinado post
      type: String,
      default: "gamePage",
    },
    mode: {
      type: String,
      default: "insert",
    },
    postId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    cardTitle() {
      return this.mode === "insert" ? this.interactionType : "Editar post";
    },
    buttonText() {
      return this.mode === "insert" ? this.interactionType : "Salvar";
    },
    roomId() {
      return this.$route.params.roomId;
    },
    gameId() {
      return this.$route.params.gameId;
    },
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      loading: false,
      loadingFallacies: false,
      successDialog: false,
      form: {
        content: "",
      },
      editorOptions: {
        modules: { toolbar: [["video", "link"], [{ align: ["justify"] }]] },
      },
      glossary: {
        'Responder/Concordar': 1,
        Discordar: 2,
        Perguntar: 3,
        "Acusar de falácia": 4,
      },
      fallaciesTypes: [],
    };
  },
  methods: {
    validateForm() {
      const errors = [];

      if (!this.form.content || this.form.content == "")
        errors.push("Preenchimento do campo de texto obrigatório");

      if (
        this.interactionType === "Acusar de falácia" &&
        !this.form.fallaciesTypeId
      )
        errors.push("Informe a falácia desejada!");

      return errors;
    },
    save() {
      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      this[this.mode]();
    },
    async insert() {
      try {
        this.loading = true;

        let dataToSend = {
          GameId: this.gameId,
          content: this.form.content,
          interactType: this.glossary[this.interactionType],
          fallaciesTypeId: this.form.fallaciesTypeId
            ? this.form.fallaciesTypeId
            : null,
          mainPost: this.mainPost,
          origin: this.origin,
        };

        /* independente da página (singlePost ou gamePage), se essa flag for 0,
        sempre enviar o id do post a qual o insert corresponde */
        if (!this.mainPost)
          dataToSend.responseToThePostId = this.responseToThePostId;

        await this.$axios.post(`/post/criar`, dataToSend);

        this.successDialog = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        let dataToSend = {
          content: this.form.content,
          fallaciesTypeId: this.form.fallaciesTypeId
            ? this.form.fallaciesTypeId
            : null,
          origin: this.origin,
        };

        /* independente da página (singlePost ou gamePage), se essa flag for 0,
        sempre enviar o id do post a qual o insert corresponde */
        if (!this.mainPost)
          dataToSend.responseToThePostId = this.responseToThePostId;

        await this.$axios.put(`/post/${this.postId}/editar`, dataToSend);

        this.successDialog = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    async getFallaciesTypes() {
      try {
        this.loading = true;
        this.loadingFallacies = true;

        const res = await this.$axios.get(`/jogos/falacias`);

        this.fallaciesTypes = [...res.data.fallacies];
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
        this.loadingFallacies = false;
      }
    },
    async initializeForm() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/post/${this.postId}`);

        this.form = { ...res.data.post };
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    closeSuccessDialog() {
      this.successDialog = false;
      this.closeDialog();
    },
    closeDialog() {
      // this.resetData();
      this.$emit("close-dialog");
    },
    resetData() {
      this.form = {
        content: "",
      };
    },
    handleError(error) {
      console.log(error);
      const errorHandled = errorHandler.treatError(error);

      errorHandled.map((e) => this.$toast.error(e));
    },
  },
  watch: {
    interactionType(value) {
      if (!value) return;

      if (value === "Acusar de falácia") this.getFallaciesTypes();
    },
    postId(value) {
      if (!value) return;

      if (this.mode === "update") this.initializeForm();
    },
  },
  created() {
    if (this.interactionType === "Acusar de falácia") this.getFallaciesTypes();
    if (this.mode === "update") this.initializeForm();
  },
};
</script>

<style></style>
